<template>
    <section class="correspondencia-listado position-relative w-100 px-4">
        <cargando v-if="cargando" />
        <div class="position-sticky bg-white py-4" style="top: 55px; z-index: 9">
            <div class="d-middle justify-content-between pl-2">
                <p class="f-20 f-500">Correspondencia</p>
                <div class="d-middle gap-3">
                    <button class="btn btn-general h-32px f-14 f-300" @click="crearCorrespondencia">
                        Crear correspondencia
                    </button>
                    <el-input v-model="search" placeholder="Buscar" prefix-icon="el-icon-search" size="small" @change="buscador" />
                </div>
            </div>
        </div>
        <div class="row overflow-auto custom-scroll" style="height:calc(100vh - 180px)">
            <el-table :data="correspondencias" height="calc(100vh - 215px)" style="width: 100%" @row-click="verCorrespondencia">
                <el-table-column prop="imagen_icono" label="Tipo" width="150">
                    <template slot-scope="scope">
                        <img :src="scope.row.imagen_icono" alt="" width="40" height="40" class="br-20" />
                    </template>
                </el-table-column>
                <el-table-column prop="entrega_nombre" label="Entrega">
                    <template slot-scope="scope">
                        {{ scope.row.entrega_nombre }}
                    </template>
                </el-table-column>
                <el-table-column prop="vivienda" label="Vivienda">
                    <template slot-scope="scope">
                        {{ scope.row.vivienda }}
                    </template>
                </el-table-column>
                <el-table-column prop="created_at" label="Fecha">
                    <template slot-scope="scope">
                        {{ scope.row.created_at | helper-fecha('DD MMM YYYY - h:m a') }}
                    </template>
                </el-table-column>
                <el-table-column prop="estado" label="Estado">
                    <template slot-scope="scope">
                        <span v-if="scope.row.estado === 1" class="bg-verde-claro rounded-pill pl-1 pr-3 py-1 f-14 text-center">
                            <i class="icon-ok-circled-outline f-14" />
                            Recibido
                        </span>
                        <span v-if="scope.row.estado === 0" class="bg-db rounded-pill pl-1 pr-3 py-1 f-14 text-center">
                            <i class="icon-clock-outline f-16" />
                            Pendiente
                        </span>
                    </template>
                </el-table-column>
                <div slot="empty" class="row">
                    <div class="col-12 text-center">
                        <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120" />
                        <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No hemos encontrado coincidencias con tu búsqueda</p>
                    </div>
                </div>
                <infinite-loading
                slot="append"
                force-use-infinite-wrapper=".el-table__body-wrapper"
                @infinite="correspondencia"
                />
            </el-table>
        </div>
    </section>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';
import Correspondencias from '~/services/correspondencia'
export default {
    components:{
        InfiniteLoading
    },
    data(){
        return{
            correspondencias:[],
            search: '',
            page: 1,
            cargando : false
        }
    },
    watch:{
        // search(){
        //     this.page = 1
        //     this.correspondencias = []
        // }
    },
    methods:{
        crearCorrespondencia(){
            this.$router.push({ name: 'correspondencia.crear' })
        },
        verCorrespondencia(row){
            this.$router.push({ name: 'correspondencia.ver', params:{idCorrespondencia : row.id} })
        },
        async correspondencia($state){
            try {
                const state = $state
                this.cargando = true
                let params= {
                    page: this.page,
                    search: this.search,
                    caso: 0
                }
                const {data} = await Correspondencias.listar(params)
                if(data.data.data.length){
                    this.page += 1;
                    this.correspondencias.push(...data.data.data)
                    if (typeof state !== 'undefined'){
                        state.loaded()
                    }
                }else{
                    if (typeof state !== 'undefined'){
                        state.complete()
                    }
                }
            
            } catch (e){
                this.errorCatch(e)
            } finally{
                this.cargando = false
            }
        },
        buscador(){
            this.page = 1
            this.correspondencias = []
            this.correspondencia()
        }
    }
}
</script>
<style lang="scss" scoped>

</style>